body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.css-1bdad8q-MuiResponsiveChart-container > svg {
  position: relative;
  top: -25px;
}
.css-heg063-MuiTabs-flexContainer {
  gap: 75px !important;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 5px !important;
}
.css-19midj6 {
  padding: 5px !important;
}

.ui.grid > .column:not(.row) {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.ui.multiple.search.dropdown > input.search {
  position: fixed !important;
}

/* accordion */
.MuiAccordionSummary-content {
  margin: 8px 0 !important;
}

*{
    padding: 0;
    margin: 0;
    scrollbar-width: thin;
    scrollbar-color: #888 transparent; /* Thumb color and track color */
}
/* Custom scrollbar styles for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
  }
  
  ::-webkit-scrollbar-track {
    background: transparent; /* Transparent background layer */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 12px; /* Full roundness for the scrollbar thumb */
    border: 3px solid transparent; /* Transparent border for padding */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Floating effect */
    background-clip: padding-box; /* Ensure the background color does not bleed */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color when hovering over the scrollbar thumb */
  }
  
  /* Remove up and down arrows */
  ::-webkit-scrollbar-button {
    display: none;
  }
  
  /* Firefox only - styling the scrollbar thumb */
  *::-moz-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 12px; /* Full roundness for the scrollbar thumb */
    border: 3px solid transparent; /* Transparent border for padding */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Floating effect */
    background-clip: padding-box; /* Ensure the background color does not bleed */
  }
  
  *::-moz-scrollbar-thumb:hover {
    background-color: #555; /* Color when hovering over the scrollbar thumb */
  }

  @media screen and (max-width: 768px) {
    body {
        padding: 5px;
    }
    .MuiContainer-root {
        padding: 5px !important;
    }
}

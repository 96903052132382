/* Center the form and user grid */
.users-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Style for the form */
.users-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

/* Add some padding and margin between cards */
.user-card {
  padding: 20px;
  border-radius: 8px;
  background-color: #f4f4f9;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 150px;
  transition: transform 0.3s ease;
}

/* Hover effect on user cards */
.user-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}


